<script lang="ts" setup>
import type { ProductDetailsType, ProductVariantType } from '~/api/product';

const TEST_ID = 'product-purchase-widget';

const props = defineProps<{
  selected?: ProductVariantType;
  refill?: boolean;
  product: ProductDetailsType;
}>();

const { t } = useI18n();
const productCartStore = useProductCartStore();
const subscribeProductsStore = useSubscribeProductsStore();

const quantity = computed(
  () =>
    productCartStore.cartData.find((item) => item.skuId === props.selected?.id)
      ?.quantity || 0,
);
const selectedPrice = computed(
  () => props.selected && priceFormat(props.selected.price),
);
const totalPrice = computed(
  () => props.selected && priceFormat(props.selected.price * quantity.value),
);
const totalBasePrice = computed(
  () =>
    props.selected?.basePrice &&
    priceFormat(props.selected.basePrice * (quantity.value || 1)),
);
const isSelectedRefill = computed(() =>
  subscribeProductsStore.productIds.some((el) => el === props.product.refillId),
);

const changeQuantity = (count: number) => {
  if (props.selected) {
    productCartStore.updateCartItemBySkuInfo(
      props.selected,
      props.product.id,
      props.product.name,
      count,
      true,
    );
  }
};

const onClick = () => {
  if (props.refill && props.product.refillId && !isSelectedRefill.value) {
    subscribeProductsStore.onToggle(true, props.product.refillId);
  }

  changeQuantity(1);
};
</script>

<template>
  <div
    class="productPurchaseWidget"
    :class="{
      productPurchaseWidget_selected: selected && quantity,
    }"
    :data-test-id="TEST_ID"
  >
    <button
      @click="onClick"
      :disabled="!selected"
      type="button"
      class="productPurchaseWidget__button productPurchaseWidget__button_main"
      :data-test-id="`${TEST_ID}-select`"
    >
      <Transition name="reel">
        <template v-if="refill">
          <i18n-t
            v-if="isSelectedRefill"
            tag="span"
            keypath="current_with_subscription"
            class="productPurchaseWidget__text_state"
          >
            <template #quantity>{{ quantity }}</template>
            <template #price>
              {{ totalPrice }}
              <span
                v-if="totalBasePrice"
                class="productPurchaseWidget__text_prev"
              >
                {{ totalBasePrice }}
              </span>
            </template>
            <template #refill>
              <span
                class="productPurchaseWidget__text_noTablet productPurchaseWidget__text_noSm"
              >
                {{ t('refill') }}
              </span>
            </template>
          </i18n-t>

          <i18n-t
            v-else
            tag="span"
            keypath="select_with_subscription"
            class="productPurchaseWidget__text_state"
          >
            <template #price>
              {{ quantity ? totalPrice : selectedPrice }}
              <span
                v-if="totalBasePrice"
                class="productPurchaseWidget__text_prev productPurchaseWidget__text_noSm"
              >
                {{ totalBasePrice }}
              </span>
            </template>
            <template #refill>
              <span
                class="productPurchaseWidget__text_noTablet productPurchaseWidget__text_noSm"
              >
                {{ t('refill') }}
              </span>
            </template>
          </i18n-t>
        </template>

        <template v-else>
          <span
            class="productPurchaseWidget__text_state"
            v-if="selected && quantity"
          >
            {{ t('current', { quantity, price: totalPrice }) }}
            <span
              v-if="totalBasePrice"
              class="productPurchaseWidget__text_prev"
            >
              {{ totalBasePrice }}
            </span>
          </span>

          <i18n-t
            keypath="select"
            tag="span"
            class="productPurchaseWidget__text_state"
            v-else-if="selected"
          >
            <template #mobile>
              <span class="productPurchaseWidget__text_noMobile">
                {{ t('mobile') }}
              </span>
            </template>
            <template #price>
              {{ selectedPrice }}
              <span
                v-if="totalBasePrice"
                class="productPurchaseWidget__text_prev"
              >
                {{ totalBasePrice }}
              </span>
            </template>
          </i18n-t>

          <span v-else>{{ t('no_stock') }}</span>
        </template>
      </Transition>
    </button>
    <Transition name="productPurchaseWidget__iconButton">
      <button
        v-if="selected && quantity"
        @click="changeQuantity(-1)"
        class="productPurchaseWidget__button productPurchaseWidget__button_icon productPurchaseWidget__button_left"
        :aria-label="t('decrease')"
      >
        <SvgoSimpleMinus class="productPurchaseWidget__icon" />
      </button>
    </Transition>
    <Transition name="productPurchaseWidget__iconButton">
      <button
        v-if="selected && quantity"
        @click="changeQuantity(1)"
        class="productPurchaseWidget__button productPurchaseWidget__button_icon productPurchaseWidget__button_right"
        :aria-label="t('increase')"
      >
        <SvgoSimplePlus class="productPurchaseWidget__icon" />
      </button>
    </Transition>
  </div>
</template>

<i18n>
ru:
  current: '{quantity} в корзине — {price}'
  select: Добавить {mobile} — {price}
  mobile:  в корзину 
  refill:  на рефил 
  current_with_subscription: '{quantity} в корзине и подписка {refill} — {price}'
  select_with_subscription: Купить и оформить подписку {refill} — {price}
  no_stock: Нет в наличии
  decrease: Уменьшить товар в корзине
  increase: Добавить товар в корзину

en:
  current: '{quantity} in cart — {price}'
  select: Add {mobile} — {price}
  mobile:  to cart 
  refill:  to refill 
  current_with_subscription: '{quantity} in cart and subscription {refill} — {price}'
  select_with_subscription: Buy and subscribe {refill} — {price}
  no_stock: Out of stock
  decrease: Decrease product in cart
  increase: Add product to cart
</i18n>

<style lang="scss">
.productPurchaseWidget {
  $self: &;

  --button-height: 90px;

  display: flex;
  position: sticky;
  margin-top: -90px;
  margin-left: auto;
  bottom: 0;
  width: 50%;
  height: var(--button-height);
  align-items: center;
  overflow: hidden;
  background-color: #8583ba;
  color: #fff;
  transition: background-color 0.3s ease;

  @include mq('md') {
    --button-height: 55px;

    width: 100%;
    position: fixed;
    margin-top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  &:hover {
    background-color: #9f9dd3;
  }

  &_selected &__text_state {
    @include mq('lg') {
      padding: 0 107px;
    }

    @include mq('md') {
      padding: 0 72px;
    }

    @include mq('xs') {
      padding: 0;
    }
  }

  &__button {
    position: absolute;
    background-color: transparent;
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    @include mq('sm') {
      font-size: 16px;
    }

    &_main {
      flex: 1;
      padding: 0 8px;

      @include mq('sm') {
        padding: 0 4px;
      }
    }

    &_icon {
      width: calc(var(--button-height) + 17px);
      transition: opacity 0.3s ease;

      @include mq('xs') {
        display: none;
      }

      &:not(:disabled):hover {
        opacity: 0.3;
      }
    }

    &:disabled {
      pointer-events: none;
      color: #878787;
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }

    &_left {
      left: 0;
      padding-left: 17px;
    }

    &_right {
      right: 0;
      padding-right: 17px;
    }
  }

  &__text {
    &_state {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
    }

    &_prev {
      display: block;
      margin-left: 10px;
      opacity: 0.4;
      text-decoration-line: line-through;
    }

    &_noMobile {
      @include mq('xs') {
        display: none;
      }
    }

    &_noSm {
      @include mq('sm') {
        display: none;
      }
    }

    &_noTablet {
      @include mq('lg', 'md') {
        display: none;
      }
    }
  }

  &__icon {
    margin-bottom: 0;
    display: block;
    width: 35px;
    height: 35px;
    padding: 4px;

    @include mq('sm') {
      width: 24px;
      height: 24px;
    }
  }

  &__iconButton {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s ease;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
