<script lang="ts" setup>
const props = defineProps<{
  content: string;
}>();

const { data } = await useAsyncData(() => parseMarkdown(props.content));
</script>

<template>
  <ContentRenderer v-if="data" :value="data" class="baseComplexText" />
</template>

<style lang="scss">
.baseComplexText {
  p {
    margin: 0;

    & + p {
      margin-top: 1.5em;
    }
  }

  strong,
  b {
    font-weight: bold;
  }
}
</style>
